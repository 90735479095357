import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const About2 = (props) => {
    return(
        <section className="about-us-section-s2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="feature-grids clearfix">
                            <div className="grid">
                                <i className="fi flaticon-diamond"></i>
                                <h3>Market Research</h3>
                                <p>Samsa was a travelling sales man and above it there </p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-aim"></i>
                                <h3>Keyword Targeting</h3>
                                <p>Samsa was a travelling sales man and above it there </p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-mail"></i>
                                <h3>Email Marketing</h3>
                                <p>Samsa was a travelling sales man and above it there </p>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="section-title-s5">
                            <span>01. About us</span>
                            <h2>Build Your Business with Our SEO Agency</h2>
                        </div>
                        <div className="about-details">
                            <h5>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated</h5>
                            <p>Picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered llustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright.</p>
                            <Link to="/about" className="theme-btn-s4">More about us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;