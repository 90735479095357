import React from 'react';
import ContactForm from '../ContactForm'
import './style.css'

const Contactpage = () => {

    return(
        <div className="contact-page-area">
            <div className="contact-section-s3 section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-12">
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="fi flaticon-house"></i>
                                        <h4>Head Office</h4>
                                        <p>654, Dash soroni road jekor dnaga, Melborn Austria</p>
                                    </li>
                                    <li>
                                        <i className="fi flaticon-email-1"></i>
                                        <h4>Email Address</h4>
                                        <p>demo@example.com</p>
                                    </li>
                                    <li>
                                        <i className="fi flaticon-call"></i>
                                        <h4>Telephone</h4>
                                        <p>++124752-324567</p>
                                    </li>
                                    <li>
                                        <i className="fi flaticon-alarm"></i>
                                        <h4>Office Hour</h4>
                                        <p>Mon-Sun: 09am – 7pm</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-8 col-12">
                            <div className="section-title">
                                <div className="icon">
                                    <i className="fi flaticon-balance"></i>
                                </div>
                                <span>Let’s get in touch</span>
                                <h2>Need Consultancy, Let’s get in touch Now!</h2>
                                <p>Raising a heavy maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nuncIt showed a lady fitted out with a fur hat and fur boa who sat upright, </p>
                            </div>
                            <div className="contact-form">
                                <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div className="contact-map-section">
                <div className="contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.9147703055!2d-74.11976314309273!3d40.69740344223377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbd!4v1547528325671" allowfullscreen></iframe>
                </div>
            </div>
        </div>
     )
        
}

export default Contactpage;
