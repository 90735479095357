import React from 'react';
import {Link} from 'react-router-dom'
import ab1 from '../../images/blog/about-widget.jpg'
import ins1 from '../../images/recent-posts/img-1.jpg'
import ins2 from '../../images/recent-posts/img-2.jpg'
import ins3 from '../../images/recent-posts/img-3.jpg'


import './style.css'

const BlogSidebar = () => {

    const SubmitHandler = (e) =>{
       e.preventDefault()
    }

    return(
        <div className="col col-lg-4 col-md-8 col-12">
            <div className="blog-sidebar">
                <div className="widget about-widget">
                    <h3>About us</h3>
                    <div className="img-holder">
                        <img src={ab1} alt=""/>
                    </div>
                    <p>Picture Samples lay spread out on the Samsa was a travelling salesman and above it there hung a that he</p>
                    <div className="social">
                        <ul className="clearfix">
                            <li><Link to="/blog-details"><i className="ti-facebook"></i></Link></li>
                            <li><Link to="/blog-details"><i className="ti-twitter-alt"></i></Link></li>
                            <li><Link to="/blog-details"><i className="ti-linkedin"></i></Link></li>
                            <li><Link to="/blog-details"><i className="ti-pinterest"></i></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="widget search-widget">
                    <h3>Search</h3>
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input type="text" className="form-control" placeholder="Search Post.."/>
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div>
                <div className="widget category-widget">
                    <h3>Categories</h3>
                    <ul>
                        <li><Link to="/blog-details">Digital Marketing <span>(2)</span></Link></li>
                        <li><Link to="/blog-details">Web Analytics <span>(3)</span></Link></li>
                        <li><Link to="/blog-details">Startup Business <span>(7)</span></Link></li>
                        <li><Link to="/blog-details">Delivery <span>(5)</span></Link></li>
                        <li><Link to="/blog-details">SEO Marketing <span>(10)</span></Link></li>
                    </ul>
                </div>
                <div className="widget recent-post-widget">
                    <h3>Recent post</h3>
                    <div className="posts">
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins1} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/blog-details">Salesman and above it there hung a picture that he</Link></h4>
                                <span className="date">13 Feb 2020</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins2} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/blog-details">Picture that he had recently out of an illustrated magazine</Link></h4>
                                <span className="date">13 Feb 2020</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins3} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/blog-details">Recently cut out of an illustrated magazine distingur aelten</Link></h4>
                                <span className="date">13 Feb 2020</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li><Link to="/blog-details">Hosting</Link></li>
                        <li><Link to="/blog-details">Business</Link></li>
                        <li><Link to="/blog-details">Server</Link></li>
                        <li><Link to="/blog-details">vps hosting</Link></li>
                        <li><Link to="/blog-details">Manfacturing</Link></li>
                    </ul>
                </div>
            </div>
        </div>
     )
        
}

export default BlogSidebar;
