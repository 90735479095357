import React, {Fragment} from 'react';
import Header from '../../components/header'
import Hero from '../../components/hero'
import Features from '../../components/features'
import TipsTricks from '../../components/TipsTricks'
import ServicesSection from '../../components/ServicesSection'
import WhyChooseUs1 from '../../components/WhyChooseUs1'
import SeoRankSection from '../../components/SeoRankSection'
import PricingSection from '../../components/pricing'
import PartnerSlider from '../../components/partner'
import TestimonialSlide from '../../components/Testimonial'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const HomePage =() => {
    return(
        <Fragment>
            <Header/>
            <Hero/>
            <Features/>
            <TipsTricks/>
            <ServicesSection/>
            <WhyChooseUs1/>
            <SeoRankSection/>
            <PricingSection/>
            <PartnerSlider/>
            <TestimonialSlide/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;