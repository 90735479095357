import React from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import VideoModal from '../../components/ModalVideo'
import blog1 from '../../images/blog/img-4.jpg'
import blog2 from '../../images/blog/img-5.jpg'
import blog3 from '../../images/blog/img-6.jpg'


const BlogLeft = () => {

    return(
        <section className="blog-pg-section blog-pg-left-sidebar section-padding">
            <div className="container">
                <div className="row">
                    <BlogSidebar/>
                    <div className="col col-lg-8 col-12-8 col-12">
                        <div className="blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={blog1} alt=""/>
                                    <div className="cat">SEO, SMM</div>
                                </div>
                                <div className="entry-meta">
                                    <span>Feb 13, 2020 </span>
                                    <span>By: <Link to="/blog-details">Admim</Link> </span>
                                </div>
                                <div className="entry-details">
                                    <h3><Link to="/blog-details">Search engine optimization but I didn’t know where to begin contacted SEO Services USA</Link></h3>
                                    <p>Samples lay spread out on the table Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with Collection of textile</p>
                                    <Link to="/blog-details" className="read-more">Read More</Link>
                                </div>
                            </div>
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={blog2} alt=""/>
                                    <div className="cat">SEO, SMM</div>
                                </div>
                                <div className="entry-meta">
                                    <span>Feb 13, 2020 </span>
                                    <span>By: <Link to="/blog-details">Admim</Link> </span>
                                </div>
                                <div className="entry-details">
                                    <h3><Link to="/blog-details">Search engine optimization but I didn’t know where to begin contacted SEO Services USA</Link></h3>
                                    <p>Samples lay spread out on the table Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with Collection of textile</p>
                                    <Link to="/blog-details" className="read-more">Read More</Link>
                                </div>
                            </div>
                            <div className="post format-video">
                                <div className="entry-media video-holder">
                                    <img src={blog3} alt=""/>
                                    <div className="video-btn">
                                        <VideoModal/>
                                    </div>
                                    <div className="cat">SEO, SMM</div>
                                </div>
                                <div className="entry-meta">
                                    <span>Feb 13, 2020 </span>
                                    <span>By: <Link to="/blog-details">Admim</Link> </span>
                                </div>
                                <div className="entry-details">
                                    <h3><Link to="/blog-details">Search engine optimization but I didn’t know where to begin contacted SEO Services USA</Link></h3>
                                    <p>Samples lay spread out on the table Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with Collection of textile</p>
                                    <Link to="/blog-details" className="read-more">Read More</Link>
                                </div>
                            </div>
                            <div className="post format-standard">
                                <div className="cat">Adovcate, Law</div>
                                <div className="entry-meta">
                                    <span>Feb 13, 2020 </span>
                                    <span>By: <Link to="/blog-details">Admim</Link> </span>
                                </div>
                                <div className="entry-details">
                                    <h3><Link to="/blog-details">Search engine optimization but I didn’t know </Link></h3>
                                    <p>Samples lay spread out on the table Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with Collection of textile</p>
                                    <Link to="/blog-details" className="read-more">Read More</Link>
                                </div>
                            </div>
                            <div className="post format-gallery">
                                <div className="entry-media">                            
                                    <img src={blog1} alt=""/>
                                    <div className="cat">SEO, SMM</div>
                                </div>
                                <div className="entry-meta">
                                    <span>Feb 13, 2020 </span>
                                    <span>By: <Link to="/blog-details">Admim</Link> </span>
                                </div>
                                <div className="entry-details">
                                    <h3><Link to="/blog-details">Search engine optimization but I didn’t know where to begin contacted SEO Services USA</Link></h3>
                                    <p>Samples lay spread out on the table Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with Collection of textile</p>
                                    <Link to="/blog-details" className="read-more">Read More</Link>
                                </div>
                            </div>
                            <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link to="/blog-details" aria-label="Previous">
                                            <i className="fi flaticon-back"></i>
                                        </Link>
                                    </li>
                                    <li className="active"><Link to="/blog-details">1</Link></li>
                                    <li><Link to="/blog-details">2</Link></li>
                                    <li><Link to="/blog-details">3</Link></li>
                                    <li>
                                        <Link to="/blog-details" aria-label="Next">
                                            <i className="fi flaticon-next"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
     )
        
}

export default BlogLeft;
