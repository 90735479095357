import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'
import AboutPage from '../AboutPage'
import TestimonialPage from '../TestimonialPage'
import TestimonialPage2 from '../TestimonialPage2'
import CasePage from '../CasePage'
import CaseSinglePage from '../CaseSinglePage'
import TeamPage from '../TeamPage'
import FaqPage from '../FaqPage'
import ErrorPage from '../ErrorPage'       
import PricingPage from '../PricingPage'       
import ServicePage from '../ServicePage'       
import ServicePage2 from '../ServicePage2'       
import ServicePage3 from '../ServicePage3'       
import ServicePage4 from '../ServicePage4'       
import ServicePage5 from '../ServicePage5'       
import ServicePage6 from '../ServicePage6'       
import ShopPage from '../ShopPage'       
import ContactPage from '../ContactPage'       
import ShopSingle from '../ShopSingle'       
import BlogPage from '../BlogPage'       
import BlogPageLeft from '../BlogPageLeft'       
import BlogPageFullwidth from '../BlogPageFullwidth'       
import BlogDetails from '../BlogDetails'       
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide'       
import BlogDetailsFull from '../BlogDetailsFull'       

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path='/home' component={Homepage} />
            <Route path='/home2' component={Homepage2} />
            <Route path='/home3' component={Homepage3} />
            <Route path='/about' component={AboutPage} />
            <Route path='/testimonial1' component={TestimonialPage} />
            <Route path='/testimonial2' component={TestimonialPage2} />
            <Route path='/case' component={CasePage} />
            <Route path='/casesingle' component={CaseSinglePage}/>
            <Route path='/team' component={TeamPage}/>
            <Route path='/faq' component={FaqPage}/>
            <Route path='/404' component={ErrorPage}/> 
            <Route path='/pricing' component={PricingPage}/> 
            <Route path='/service' component={ServicePage}/> 
            <Route path='/service2' component={ServicePage2}/> 
            <Route path='/service3' component={ServicePage3}/> 
            <Route path='/service4' component={ServicePage4}/> 
            <Route path='/service5' component={ServicePage5}/> 
            <Route path='/service6' component={ServicePage6}/> 
            <Route path='/shop' component={ShopPage}/> 
            <Route path='/shop-single' component={ShopSingle}/> 
            <Route path='/contact' component={ContactPage}/> 
            <Route path='/blog' component={BlogPage}/> 
            <Route path='/blog-left' component={BlogPageLeft}/> 
            <Route path='/blog-fullwidth' component={BlogPageFullwidth}/> 
            <Route path='/blog-details' component={BlogDetails}/> 
            <Route path='/blog-details-left' component={BlogDetailsLeftSiide}/> 
            <Route path='/blog-details-fullwidth' component={BlogDetailsFull}/> 
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
