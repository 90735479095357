import React from 'react'
import Logo from '../../images/logo.png'
import {Link}  from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'
import min1 from '../../images/shop/mini-cart/img-1.jpg'
import min2 from '../../images/shop/mini-cart/img-2.jpg'
import './style.css'

const Header = () => {
    const SubmitHandler = (e) =>{
        e.preventDefault()
     }

    return(	
	<div className="middle-header header-style-3">
        <div className="container-fluid">
            <div className="header-content">
                <div className="logo">
                    <Link to="#" title=""><img src={Logo} alt=""/></Link>
                </div>
                <nav className="d-lg-block d-none">
                    <ul>
                        <li><Link className="active" to="/home" title="">Home</Link>
                            <ul>
                                <li><Link className="active" to="/home">Home style 1</Link></li>
                                <li><Link to="/home2">Home style 2</Link></li>
                                <li><Link to="/home3">Home style 3</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/" title="">Pages</Link>
                            <ul>
                                <li><Link to="/about" title="">About</Link></li>
                                <li><Link to="/contact" title="">Contact</Link></li>
                                <li><Link to="/testimonial1" title="">Testimonials</Link>
                                    <ul>
                                        <li><Link to="/testimonial1" title="">Testimonials</Link></li>
                                        <li><Link to="/testimonial2" title="">Testimonials style 2</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/case" title="">Case studies</Link>
                                    <ul>
                                        <li><Link to="/case" title="">Case studies</Link></li>
                                        <li><Link to="/casesingle" title="">Case studies single</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/team" title="">Team</Link></li>
                                <li><Link to="/faq" title="">FAQs</Link></li>
                                <li><Link to="/404" title="">Error 404</Link></li>
                                <li><Link to="/pricing" title="">Pricing</Link></li>
                            </ul>
                        </li>
                        <li><Link  to="/service">Services</Link>
                            <ul>
                                <li><Link  to="/service">Services</Link></li>
                                <li><Link  to="/service2">Services style 2</Link></li>
                                <li><Link  to="/service3">Services style 3</Link></li>
                                <li><Link  to="/service4">Search engine optimization</Link></li>
                                <li><Link  to="/service5">Social media marketing</Link></li>
                                <li><Link  to="/service6">Content Merketing</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/blog">Blog</Link>
                            <ul>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/blog-left">Blog Left sidebar</Link></li>
                                <li><Link to="/blog-fullwidth">Blog full width</Link></li>
                                <li><Link to="/blog-details" title="">Blog Details</Link>
                                    <ul>
                                        <li><Link to="/blog-details" title="">Blog single</Link></li>
                                        <li><Link to="/blog-details-left" title="">Blog single Left sidebar</Link></li>
                                        <li><Link to="/blog-details-fullwidth" title="">Blog single full width</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><Link to="/" title="">Shop</Link>
                            <ul>
                                <li><Link to="/shop" title="">Shop</Link></li>
                                <li><Link to="/shop-single" title="">Shop single</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/contact" title="">Contact</Link></li>
                    </ul>
                </nav>
                <div className="contact">
                    <div className="cart-search-contact">
                        <div className="header-search-form-wrapper">
                            <button className="search-toggle-btn"><i className="fi flaticon-search"></i></button>
                            <div className="header-search-form">
                                <form onSubmit={SubmitHandler}>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Search here..."/>
                                        <button type="submit"><i className="ti-search"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="mini-cart">
                            <button className="cart-toggle-btn"> <i className="fi flaticon-shopping-basket"></i> <span className="cart-count">02</span></button>
                            <div className="mini-cart-content">
                                <div className="mini-cart-items">
                                    <div className="mini-cart-item clearfix">
                                        <div className="mini-cart-item-image">
                                            <Link to="/"><img src={min1} alt=""/></Link>
                                        </div>
                                        <div className="mini-cart-item-des">
                                            <Link to="/">Hoodi with zipper</Link>
                                            <span className="mini-cart-item-price">$20.15</span>
                                            <span className="mini-cart-item-quantity">x 1</span>
                                        </div>
                                    </div>
                                    <div className="mini-cart-item clearfix">
                                        <div className="mini-cart-item-image">
                                            <Link to="/"><img src={min2} alt=""/></Link>
                                        </div>
                                        <div className="mini-cart-item-des">
                                            <Link to="/">Ninja T-shirt</Link>
                                            <span className="mini-cart-item-price">$13.25</span>
                                            <span className="mini-cart-item-quantity">x 2</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mini-cart-action clearfix">
                                    <span className="mini-checkout-price">$215.14</span>
                                    <Link to="/" className="view-cart-btn">View Cart</Link>
                                </div>
                            </div>
                        </div>
                        <Link to="/contact" className="theme-btn-s2">Contact us <i className="ti-angle-right"></i></Link>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
            <MobileMenu/>
        </div>
    </div>
    )
}

export default Header;