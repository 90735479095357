import React from 'react'
import './style.css'

const Features = (props) => {
    return(
        <section className="features-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8">
                        <div className="section-title">
                            <span>Check Our Features</span>
                            <h2>Dynamic team of creative people, Innovation and Degital marketing expert.</h2>
                            <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="feature-grids clearfix">
                            <div className="grid">
                                <i className="fi flaticon-diamond"></i>
                                <h3>Market Research</h3>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine valuealb</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-aim"></i>
                                <h3>Keyword Targeting</h3>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine valuealb</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-mail"></i>
                                <h3>Email Marketing</h3>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine valuealb</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default Features;