import React from 'react'
import './style.css'
import {Link} from 'react-router-dom'
import vimg from '../../images/video-img.jpg'
import VideoModal from '../../components/ModalVideo'

const VedioCta = (props) => {
    return(
        <section className="video-cta-area">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="img-holder">
                             <img src={vimg} alt=""/>
                             <div className="video-btn">
                                <VideoModal/>
                             </div>
                        </div>
                        <div className="content">
                            <h2>have a query? then fell free to contact with us</h2>
                            <p>Picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright picture that he had recently cut out of an illustrated magazine</p>
                            <Link to="/contact" className="theme-btn-s4">Contact With Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VedioCta;