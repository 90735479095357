import React, {Fragment} from 'react';
import Header2 from '../../components/header2'
import Hero3 from '../../components/hero3'
import About2 from '../../components/about2'
import WhyChoice from '../../components/whychoose'
import ServicesSection3 from '../../components/ServicesSection3'
import Portfolio from '../../components/Portfolio'
import TestimonialSlide2 from '../../components/Testimonial2'
import VedioCta from '../../components/vediocta'
import PartnerSlider from '../../components/partner'
import BlogSection from '../../components/BlogSection'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const HomePage3 =() => {
    return(
        <Fragment>
            <Header2/>
            <Hero3/>
            <About2/>
            <WhyChoice/>
            <ServicesSection3/>
            <Portfolio/>
            <VedioCta/>
            <TestimonialSlide2 tmclass={'testimonials-section-s3'}/>
            <BlogSection/>
            <PartnerSlider prclass={'partners-section-s2'}/>
            <Footer subclass={'site-footer-s3'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage3;