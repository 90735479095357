import React from 'react'
import './style.css'
import {Link} from 'react-router-dom'
import shop1 from '../../images/shop/img-1.jpg'
import shop2 from '../../images/shop/img-2.jpg'
import shop3 from '../../images/shop/img-3.jpg'
import shop4 from '../../images/shop/img-4.jpg'
import shop5 from '../../images/shop/img-5.jpg'
import shop6 from '../../images/shop/img-6.jpg'

const ShopSection = (props) => {
    return(
        <section className="shop-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="shop-grids">
                            <div className="grid">
                                <div className="img-cart">
                                    <div className="img-holder">
                                        <img src={shop1} alt=""/>
                                    </div>
                                    <div className="cart-details">
                                        <ul>
                                            <li><Link to="/shop"><i className="fi flaticon-view"></i></Link></li>
                                            <li><Link to="/shop"><i className="fi flaticon-shopping-basket"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="details">
                                    <h4><Link to="/shop">Fashion running shoes</Link></h4>
                                    <del>$25.00</del>
                                    <span className="price">$22.00</span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-cart">
                                    <div className="img-holder">
                                        <img src={shop2} alt=""/>
                                    </div>
                                    <div className="cart-details">
                                        <ul>
                                            <li><Link to="/shop"><i className="fi flaticon-view"></i></Link></li>
                                            <li><Link to="/shop"><i className="fi flaticon-shopping-basket"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="details">
                                    <h4><Link to="/shop">Women leather shoes</Link></h4>
                                    <del>$25.00</del>
                                    <span className="price">$22.00</span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-cart">
                                    <div className="img-holder">
                                        <img src={shop3} alt=""/>
                                    </div>
                                    <div className="cart-details">
                                        <ul>
                                            <li><Link to="/shop"><i className="fi flaticon-view"></i></Link></li>
                                            <li><Link to="/shop"><i className="fi flaticon-shopping-basket"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="details">
                                    <h4><Link to="/shop">Cassic women gold watch</Link></h4>
                                    <del>$25.00</del>
                                    <span className="price">$22.00</span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-cart">
                                    <div className="img-holder">
                                        <img src={shop4} alt=""/>
                                    </div>
                                    <div className="cart-details">
                                        <ul>
                                            <li><Link to="/shop"><i className="fi flaticon-view"></i></Link></li>
                                            <li><Link to="/shop"><i className="fi flaticon-shopping-basket"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="details">
                                    <h4><Link to="/shop">White smartwatch</Link></h4>
                                    <del>$25.00</del>
                                    <span className="price">$22.00</span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-cart">
                                    <div className="img-holder">
                                        <img src={shop5} alt=""/>
                                    </div>
                                    <div className="cart-details">
                                        <ul>
                                            <li><Link to="/shop"><i className="fi flaticon-view"></i></Link></li>
                                            <li><Link to="/shop"><i className="fi flaticon-shopping-basket"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="details">
                                    <h4><Link to="/shop">Isometric chair 3d</Link></h4>
                                    <del>$25.00</del>
                                    <span className="price">$22.00</span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-cart">
                                    <div className="img-holder">
                                        <img src={shop6} alt=""/>
                                    </div>
                                    <div className="cart-details">
                                        <ul>
                                            <li><Link to="/shop"><i className="fi flaticon-view"></i></Link></li>
                                            <li><Link to="/shop"><i className="fi flaticon-shopping-basket"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="details">
                                    <h4><Link to="/shop">Fashon bag</Link></h4>
                                    <del>$25.00</del>
                                    <span className="price">$22.00</span>
                                </div>
                            </div>
                        </div>
                        <div className="pagination-wrapper pagination-wrapper-left">
                            <ul className="pg-pagination">
                                <li>
                                    <Link to="/shop" aria-label="Previous">
                                        <i className="fi flaticon-back"></i>
                                    </Link>
                                </li>
                                <li className="active"><Link to="/shop">1</Link></li>
                                <li><Link to="/shop">2</Link></li>
                                <li><Link to="/shop">3</Link></li>
                                <li>
                                    <Link to="/shop" aria-label="Next">
                                        <i className="fi flaticon-next"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShopSection;