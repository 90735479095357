import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {Link} from 'react-router-dom'

const ShopSingleDec = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div className="row">
        <div className="col col-xs-12">
            <div className="product-info">
                <Nav tabs>
                    <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        Description
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                    Review
                    </NavLink>
                    </NavItem>
                </Nav>
                
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="2">
                    <div className="row">
                        <div className="col col-xs-12 review-form-wrapper">
                            <div className="review-form">
                                <h4>Here you can review the item</h4>
                                <form>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Name *" required/>
                                    </div>
                                    <div>
                                        <input type="email" className="form-control" placeholder="Email *" required/>
                                    </div>
                                    <div>
                                        <textarea className="form-control" placeholder="Review *"></textarea>
                                    </div>
                                    <div className="rating-wrapper">
                                        <div className="rating">
                                            <Link to="/shop-single" title="1 Out of 5">
                                                <i className="ti-star"></i>
                                            </Link>
                                            <Link to="/shop-single" title="2 Out of 5">
                                                <i className="ti-star"></i>
                                                <i className="ti-star"></i>
                                            </Link>
                                            <Link to="/shop-single" title="3 Out of 5">
                                                <i className="ti-star"></i>
                                                <i className="ti-star"> </i>
                                                <i className="ti-star"></i>
                                            </Link>
                                            <Link to="/shop-single" title="4 Out of 5">
                                                <i className="ti-star"></i>
                                                <i className="ti-star"></i>
                                                <i className="ti-star"></i>
                                                <i className="ti-star"></i>
                                            </Link>
                                            <Link to="/shop-single" title="5 Out of 5">
                                                <i className="ti-star"></i>
                                                <i className="ti-star"></i>
                                                <i className="ti-star"></i>
                                                <i className="ti-star"></i>
                                                <i className="ti-star"></i>
                                            </Link>
                                        </div>
                                        <div className="submit">
                                            <button type="submit" className="theme-btn">Post review</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </TabPane>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <div >
                                    <p>When Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs</p>
                                    <p>He thought it wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls. A collection of textile samples lay spread out on the table Samsa was a travelling salesman and above it there hung</p>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>

            </div>
        </div>
    </div>

  );
}

export default ShopSingleDec;


