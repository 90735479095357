import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo.png'

import './style.css'

const Footer = (props) =>{
  return(
    <footer className={`site-footer ${props.subclass}`}>
        <div className="upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <img src={Logo} alt=""/>
                            </div>
                            <p>5443 Nogaline Lane, South Dasing city, CA, United States</p>
                            <div className="social-icons">
                                <ul>
                                    <li><Link to="/"><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-linkedin"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-pinterest"></i></Link></li>
                                    <li><Link to="/"><i className="fa fa-vimeo"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Company</h3>
                            </div>
                            <ul>
                                <li><Link to="/about">About us</Link></li>
                                <li><Link to="/service">Our services</Link></li>
                                <li><Link to="/contact">Contact us</Link></li>
                                <li><Link to="/team">Meet team</Link></li>
                            </ul>
                            <ul>
                                <li><Link to="/about">Provacu Policy</Link></li>
                                <li><Link to="/testimonial1">Testimonials</Link></li>
                                <li><Link to="/blog">News</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget service-link-widget">
                            <div className="widget-title">
                                <h3>Services</h3>
                            </div>
                            <ul>
                                <li><Link to="/service4">SEO Optimization</Link></li>
                                <li><Link to="/service5">Social Media Merketing</Link></li>
                                <li><Link to="/service6">Link Building</Link></li>
                                <li><Link to="/service2">Reports & Analytics</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget newsletter-widget">
                            <div className="widget-title">
                                <h3>Newsletter</h3>
                            </div>
                            <p>You will be notified when somthing new will be appear.</p>
                            <form>
                                <div className="input-1">
                                    <input type="email" className="form-control" placeholder="Email Address *" required/>
                                </div>
                                <div className="submit clearfix">
                                    <button type="submit"><i className="fa fa-envelope-o"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        <div className="lower-footer">
            <div className="container">
                <div className="row">
                    <div className="separator"></div>
                    <div className="col col-xs-12">
                        <p className="copyright">Copyright &copy; 2020 Seoul. All rights reserved.</p>
                        <div className="extra-link">
                            <ul>
                                <li><Link to="/about">Privace & Policy</Link></li>
                                <li><Link to="/about">Terms</Link></li>
                                <li><Link to="/about">About us</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;