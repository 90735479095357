import React from 'react'
import {Link} from 'react-router-dom'


const ServicesSectionPage3 = (props) => {
    return(
        <section className="services-section-s3 services-section-s3-page section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="service-grids">
                            <div className="grid">
                                <i className="fi flaticon-rocket"></i>
                                <h3><Link to="/home3">SEO Optimization</Link></h3>
                                <p>Magazine and housed in a gilded in frame It showed a lady</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-content"></i>
                                <h3><Link to="/home3">Content Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame It showed a lady</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-email"></i>
                                <h3><Link to="/home3">Email Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame It showed a lady</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-facebook"></i>
                                <h3><Link to="/home3">Social Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame It showed a lady</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-strategy"></i>
                                <h3><Link to="/home3">PPC Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame It showed a lady</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-chain"></i>
                                <h3><Link to="/home3">Link Building</Link></h3>
                                <p>Magazine and housed in a gilded in frame It showed a lady</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesSectionPage3;