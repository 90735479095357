import React, {Fragment} from 'react';
import Header2 from '../../components/header2'
import Hero2 from '../../components/hero2'
import Features2 from '../../components/features2'
import About from '../../components/about'
import WhyChoice from '../../components/whychoose'
import ServicesSection2 from '../../components/ServicesSection2'
import FunFact from '../../components/funfact'
import TestimonialSlide2 from '../../components/Testimonial2'
import VedioCta from '../../components/vediocta'
import TeamSection from '../../components/team'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const HomePage2 =() => {
    return(
        <Fragment>
            <Header2/>
            <Hero2/>
            <Features2/>
            <About/>
            <WhyChoice/>
            <ServicesSection2/>
            <FunFact/>
            <TestimonialSlide2/>
            <VedioCta/>
            <TeamSection/>
            <Footer subclass={'site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage2;