import React from 'react'
import {Link}  from 'react-router-dom'
import rkt1 from '../../images/hero-s3-vec.png'

const Hero3 = (props) => {

    return(
        <section className="hero-slider hero-style-3">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div className="slide-title">
                            <h2>More traffic </h2>
                            <span>Best Seo Agency</span>
                        </div>
                        <div className="slide-text">
                            <p>Recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and</p>
                        </div>
                        <div className="clearfix"></div>
                        <div data-swiper-parallax="500" className="slide-btns">
                            <Link to="/about" className="theme-btn">More About Us</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-s3-vec">
                <img src={rkt1} alt=""/>
            </div>
        </section>
    )
}

export default Hero3;