import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import ShopSingleDec from '../ShopSingleDec'
import ShopSingleImage from '../ShopSingleImage'
import './style.css'


class ShopSingleSection extends Component{

    submitHandler = (e) => {
        e.preventDefault()
    }
    state= {
     qty:2
    }

    increment = () => {
       this.setState({
           qty: this.state.qty+1
       })
    }

    decrement = () => {
        this.setState({
            qty: this.state.qty-1
        })
    }

    

    render(){
        
        return(
        
            <section className="shop-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-6 col-md-12 col-12">
                            <ShopSingleImage/>
                        </div>
                        <div className="col col-lg-6 col-md-12 col-12">
                            <div className="product-details">
                                <h2>Samples lay spread out on the table Samsa was</h2>
                                <div className="rating">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star-o"></i>
                                </div>
                                <div className="price">
                                    <span className="current">$45.00</span>
                                    <span className="old">$81.99</span>
                                </div>
                                <p>Textile samples lay spread out on the table Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright raising.</p>
                                <div className="product-option">
                                    <form onSubmit={this.submitHandler} className="form">
                                        <div className="product-row">
                                            <div>
                                                <div className="handle-counter" id="handleCounter">
                                                    <input type="text" value={this.state.qty} onChange={() => console.log()} />
                                                    <div className="lft-cntrs">
                                                        <span onClick={this.increment} className="counter-plus btn"><i className="fa fa-caret-up"></i></span>
                                                        <span onClick={this.decrement} className="counter-minus btn"><i className="fa fa-caret-down"></i></span>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn-style2">Add To Cart</button>

                                                {/* <input id="product-count" type="text" value="1" name="product-count"/> */}
                                            </div>
                                            <div>
                                                <button type="submit" className="theme-btn">Add to cart</button>
                                            </div>
                                            <div>
                                                <button className="theme-btn heart-btn"><i className="ti-heart"></i></button>
                                                <span></span>
                                            </div>
                                        </div>
                                    </form>
                                </div> 
                                <div className="product-meta">
                                    <div className="sku_wrapper">
                                        <span className="label">SKU:</span>
                                        <span className="sku">Blue and-neon red joy</span>
                                    </div>
                                    <div className="posted_in">
                                        <span className="label">Category:</span>
                                        <Link to="/shop-single">Body</Link>
                                    </div>
                                    <div className="tagged_as">
                                        <span className="label">Tags:</span>
                                        <Link to="/shop-single">Electronics</Link>,
                                        <Link to="/shop-single">Metal</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ShopSingleDec/>
                </div> 
            </section>
        )
    }
    
}

export default ShopSingleSection;