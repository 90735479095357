import React from 'react'
import {Link} from 'react-router-dom'

import './style.css'

const ServicesSection = (props) => {
    return(
        <section className="services-section section-padding">
            <div className="shape">
                <div className="line">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-md-1">
                        <div className="section-title-s3">
                            <span>Services</span>
                            <h2>We Are Specialized in the Following Services</h2>
                            <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine valuealb</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="service-grids">
                            <div className="grid">
                                <i className="fi flaticon-rocket"></i>
                                <h3><Link to='/'>SEO Optimization</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-content"></i>
                                <h3><Link to='/'>Content Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-email"></i>
                                <h3><Link to='/'>Email Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-facebook"></i>
                                <h3><Link to='/'>Social Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-strategy"></i>
                                <h3><Link to='/'>PPC Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-chain"></i>
                                <h3><Link to='/'>Link Building</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default ServicesSection;