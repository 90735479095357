import React from 'react'
import simg from '../../images/service-vec.png'
import {Link} from 'react-router-dom'

const ServicesSectionPage2 = (props) => {
    return(
        <section className="services-section-s2 services-pg-s2-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-4 col-12">
                        <div className="service-grids right-align">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-rocket"></i>
                                </div>
                                <h3><Link to="/">SEO Optimization</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-content"></i>
                                </div>
                                <h3><Link to="/">Content Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-email"></i>
                                </div>
                                <h3><Link to="/">Email Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-12">
                        <div className="img-holder">
                            <img src={simg} alt=""/>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-12">
                        <div className="service-grids right-col">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-facebook"></i>
                                </div>
                                <h3><Link to="/">Social Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-pay-per-click"></i>
                                </div>
                                <h3><Link to="/">Social Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-link"></i>
                                </div>
                                <h3><Link to="/">Link Building</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesSectionPage2;