import React from 'react'
import seo1 from '../../images/rocket-2.png'

import './style.css'

const SeoRankSection = (props) => {
    const submiHandler = (e) =>{
      e.preventDefault()
    }
    return(
        <section className="seo-rank-section">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2">
                        <div className="content-area">
                            <h2>Check your website SEO</h2>
                            <div className="analytic-form">
                                <form className="clearfix" onSubmit={submiHandler} >
                                    <div className="input-1">
                                        <input type="text" className="form-control" placeholder="Web url" required/>
                                    </div>
                                    <div className="input-1 input-2">
                                        <input type="email" className="form-control" placeholder="Email Address" required/>
                                    </div>
                                    <div className="submit clearfix">
                                        <button type="submit"> Check Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rocket">
                    <img src={seo1} alt=""/>
                </div>
            </div> 
        </section>
    )
}

export default SeoRankSection;