import React from 'react'
import './style.css'

const Features2 = (props) => {
    return(
        <section className="features-section-s2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="feature-grids clearfix">
                            <div className="grid">
                                <i className="fi flaticon-diamond"></i>
                                <h3>Market Research</h3>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine valuealb</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-aim"></i>
                                <h3>Keyword Targeting</h3>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine valuealb</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-mail"></i>
                                <h3>Email Marketing</h3>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine valuealb</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features2;