import React from 'react'
import wh1 from '../../images/why-choose-us-vec.png'
import './style.css'

const WhyChoice = (props) => {
    return(
        <section className="why-choose-us-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                        <div className="section-title-s6">
                            <span>02. Why chose us</span>
                            <h2>We provide better service for Your Business</h2>
                            <p> picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="img-holder">
                            <img src={wh1} alt=""/>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="why-choose-grids">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-diamond"></i>
                                </div>
                                <h3>Keyword Research</h3>
                                <p>Recently cut out of an illustrated magazine and housed in a nice, gilded</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-content"></i>
                                </div>
                                <h3>Content Optimization</h3>
                                <p>Recently cut out of an illustrated magazine and housed in a nice, gilded</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-start"></i>
                                </div>
                                <h3>Great Support</h3>
                                <p>Recently cut out of an illustrated magazine and housed in a nice, gilded</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChoice;