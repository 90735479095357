import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const PricingSection = (props) => {
    return(
        <section className="pricing-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-md-8 offset-lg-2 col-sm-10 offset-lg-1">
                        <div className="section-title-s2">
                            <span>Pricing</span>
                            <h2>No hidden charges! Choose your best plan.</h2>
                            <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine valuealb</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="pricing-grids">
                            <div className="grid">
                                <div className="pricing-header">
                                    <h5>Startup Plan</h5>
                                    <i className="fi flaticon-diamond"></i>
                                    <h1 className="price"><span>$</span>139</h1>
                                    <p>Per Month</p>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li>Finance Business & Analysing</li>
                                        <li>Managment & Marketing</li>
                                        <li>1 Top 10 Ranking Gurantee</li>
                                        <li>24/7 Customer Support</li>
                                    </ul>
                                    <Link to="/pricing" className="theme-btn-s3">Select The Plan</Link>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="hilight-tag">Most Popular</div>
                                <div className="pricing-header">
                                    <h5>Professional Plan</h5>
                                    <i className="fi flaticon-speaker"></i>
                                    <h1 className="price"><span>$</span>239</h1>
                                    <p>Per Month</p>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li>Finance Business & Analysing</li>
                                        <li>Managment & Marketing</li>
                                        <li>1 Top 10 Ranking Gurantee</li>
                                        <li>24/7 Customer Support</li>
                                    </ul>
                                    <Link to="/pricing" className="theme-btn-s3">Select The Plan</Link>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="pricing-header">
                                    <h5>Premium Plan</h5>
                                    <i className="fi flaticon-coffee-cup"></i>
                                    <h1 className="price"><span>$</span>339</h1>
                                    <p>Per Month</p>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li>Finance Business & Analysing</li>
                                        <li>Managment & Marketing</li>
                                        <li>1 Top 10 Ranking Gurantee</li>
                                        <li>24/7 Customer Support</li>
                                    </ul>
                                    <Link to="/pricing" className="theme-btn-s3">Select The Plan</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default PricingSection;