import React from 'react'
import wh1 from '../../images/why-choose-us-vec.png'

import './style.css'

const WhyChooseUs1 = (props) => {
    return(
        <section className={`why-choose-us-s1 section-padding ${props.abclass}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-md-12 col-12">
                        <div className="section-title">
                            <span>We are the best</span>
                            <h2>Why Choose Us</h2>
                            <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and housed</p>
                        </div>
                        <div className="why-choose-grids">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-start"></i>
                                </div>
                                <h3>First Working Prosses</h3>
                                <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-diamond"></i>
                                </div>
                                <h3>High-End Anaylizing</h3>
                                <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-content"></i>
                                </div>
                                <h3>Dedicate Team</h3>
                                <p>Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright</p>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-md-12 col-12">
                        <div className="img-holder">
                            <img src={wh1 } alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs1;