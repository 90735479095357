import React from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import './style.css'
import blog1 from '../../images/blog/img-4.jpg'
import blog2 from '../../images/blog-details/author.jpg'
import blog3 from '../../images/blog-details/comments-author/img-1.jpg'
import blog4 from '../../images/blog-details/comments-author/img-2.jpg'
import blog5 from '../../images/blog-details/comments-author/img-3.jpg'


const BlogSingle = () => {
    const submitHandler = (e) => {
        e.preventDefault()
    }

    return(
        <section className="blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-12-8 col-12">
                        <div className="blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={blog1} alt=""/>
                                </div>
                                <div className="date-entry-meta">
                                    <div className="entry-meta">
                                        <span>Feb 13, 2020</span>
                                        <span>By: <Link to="/blog-details">Admim</Link> </span>
                                    </div>
                                </div>
                                <h2>Search engine optimization but I didn’t know where to begin contacted SEO Services USA</h2>
                                <p>Woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What's happened to me?" he thought. It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls Dahsing when Gregor Samsa </p>
                                <p>He lay on his armour-like back Over the in his bed into a horrible vermin. , and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready</p>
                                <h3>His room, a proper human room although a little too small, lay peacefully between its four familiar</h3>
                                <p>What's happened to me he thought Nigurned to look out the window at the dull weather. Pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. . It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four </p>
                                <blockquote>
                                    Slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready
                                    <span className="quoter">- Jhone dow</span>
                                </blockquote>
                            </div>
                            <div className="tag-share clearfix">
                                <div className="tag">
                                    <ul>
                                        <li><Link to="/blog-details">Seo</Link></li>
                                        <li><Link to="/blog-details">Smm</Link></li>
                                        <li><Link to="/blog-details">Content</Link></li>
                                    </ul>
                                </div>
                                <div className="share">
                                    <ul>
                                        <li><Link to="/blog-details"><i className="ti-facebook"></i></Link></li>
                                        <li><Link to="/blog-details"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link to="/blog-details"><i className="ti-linkedin"></i></Link></li>
                                        <li><Link to="/blog-details"><i className="ti-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div> 
                            <div className="author-box">
                                <div className="author-avatar">
                                    <Link to="/blog-details" target="_blank"> <img src={blog2} alt=""/></Link>
                                </div>
                                <div className="author-content">
                                    <Link to="/blog-details" className="author-name">Jonathon tro</Link>
                                    <p>Raising a heavy fur muff that covered the whole of her lower arm towards the viewer gregor then turned to look out the window.</p>
                                    <div className="socials">
                                        <ul className="social-link">
                                            <li><Link to="/blog-details"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="/blog-details"><i className="ti-twitter-alt"></i></Link></li>
                                            <li><Link to="/blog-details"><i className="ti-linkedin"></i></Link></li>
                                            <li><Link to="/blog-details"><i className="ti-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> 
                            <div className="more-posts">
                                <div className="previous-post">
                                    <Link to="/blog-details">
                                        <span className="post-control-link">Previous Post</span>
                                        <span className="post-name">Distting covered the whole of her lower arm towards the cover</span>
                                    </Link>
                                </div>
                                <div className="next-post">
                                    <Link to="/blog-details">
                                        <span className="post-control-link">Next Post</span>
                                        <span className="post-name">Was then turned to look out the window at the dull nolonger.</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title">5 Comments</h3>
                                    <ol className="comments">
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt=""/></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>Newtong Jhon <span className="comments-date">says:</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to cover them game initials</p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to="/blog-details"><i className="fi flaticon-replay-arrow"></i>Reply</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="children">
                                                <li className="comment">
                                                    <div>
                                                        <div className="comment-theme">
                                                            <div className="comment-image"><img src={blog4} alt=""/></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4>Newtong Jhon <span className="comments-date">says:</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to cover them game initials</p>
                                                                    <div className="comments-reply">
                                                                        <Link className="comment-reply-link" to="/blog-details"><i className="fi flaticon-replay-arrow"></i>Reply</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"><img src={blog5} alt=""/></div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>Newtong Jhon <span className="comments-date">says:</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to cover them game initials</p>
                                                                            <div className="comments-reply">
                                                                                <Link className="comment-reply-link" to="/blog-details"><i className="fi flaticon-replay-arrow"></i>Reply</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="comment">
                                            <div>
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt=""/></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>Newtong Jhon <span className="comments-date">says:</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to cover them game initials</p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to="/blog-details"><i className="fi flaticon-replay-arrow"></i>Reply</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div> 
                                <div className="comment-respond">
                                    <h3 className="comment-reply-title">Leave a Reply</h3>
                                    <form onSubmit={submitHandler} id="commentform" className="comment-form">
                                        <div className="form-textarea">
                                            <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                                        </div>
                                        <div className="form-inputs">
                                            <input placeholder="Website" type="url"/>
                                            <input placeholder="Name" type="text"/>
                                            <input placeholder="Email" type="email"/>
                                        </div>
                                        <div className="form-submit">
                                            <input id="submit" value="Post Comment" type="submit"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar/>
                </div>
            </div>
        </section>
     )
        
}

export default BlogSingle;
