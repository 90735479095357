import React from 'react'
import './style.css'
import {Link}  from 'react-router-dom'
import rkt from '../../images/rocket.png'
const Hero = (props) => {

    return(
        <section className="hero-slider hero-style-1">
            <div className="line">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div className="slide-title">
                            <h2>How much traffic should you actually be getting!</h2>
                        </div>
                        <div className="slide-text">
                            <p>Gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole</p>
                        </div>
                        <div className="clearfix"></div>
                        <div data-swiper-parallax="500" className="slide-btns">
                            <Link to="/about" className="theme-btn">More About Us</Link>
                        </div>
                        <div className="rocket-area">
                            <img src={rkt} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;