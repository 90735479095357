import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import {Card,Button} from 'react-bootstrap'
import About from '../../components/about'
const CurrentMarket = (props) => {
    return(
        <section className={`faq-funfact-section section-padding service-single-faq-sec ${props.ffclass}`}>
            <div className="container fq-s">
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="section-title-s8">
                            <h2>You can get question answer from here!</h2>
                            <p>Recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her</p>
                        </div>
                        <Accordion className="theme-accordion-s1" defaultActiveKey="0">
                            <Card className="panel panel-default">
                                <div className="panel-heading">
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    1. Question number one, get answer form here
                                </Accordion.Toggle>
                                </div>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>Had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="panel panel-default">
                                <div className="panel-heading">
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                2. Question number two, get answer form here
                                </Accordion.Toggle>
                                </div>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>Had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="panel panel-default">
                                <div className="panel-heading">
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                3. Question number two, get answer form here
                                </Accordion.Toggle>
                                </div>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>Had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="fun-fact-grids clearfix">
                            <div className="grid">
                                <div className="info">
                                    <h3><span className="odometer" data-count="550">550</span>+</h3>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3><span className="odometer" data-count="50">50</span>+</h3>
                                    <p>Team Merbers</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3><span className="odometer" data-count="125">125</span></h3>
                                    <p>Projects</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3><span className="odometer" data-count="15">15</span></h3>
                                    <p>Awards</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <About/>
        </section>
    )
}

export default CurrentMarket;