import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import ServicesSectionPage3 from '../../components/ServicesSectionPage3'
import WhyChoice from '../../components/whychoose'
import TipsTricks from '../../components/TipsTricks'
import PricingSection from '../../components/pricing'
import PartnerSlider from '../../components/partner'
import TestimonialSlide from '../../components/Testimonial'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const ServicePage4 =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Search engine optimization'}/>
            <ServicesSectionPage3/>
            <TipsTricks/>
            <WhyChoice/>
            <PricingSection/>
            <PartnerSlider/>
            <TestimonialSlide/>
            <Footer subclass={'site-footer-s3'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicePage4;