import React from 'react'
import simg from '../../images/service-vec.png'
import {Link} from 'react-router-dom'

import './style.css'

const ServicesSection2 = (props) => {
    return(
        <section className="services-section-s2 section-padding">
            <div className="shape">
                <div className="line">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                        <div className="section-title-s7">
                            <span>03. Best services</span>
                            <h2>We provide better service for Your Business</h2>
                            <p> picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-lg-4 col-12">
                        <div className="service-grids right-align">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-rocket"></i>
                                </div>
                                <h3><Link to="/">SEO Optimization</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-content"></i>
                                </div>
                                <h3><Link to="/">Content Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-email"></i>
                                </div>
                                <h3><Link to="/">Email Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-12">
                        <div className="img-holder">
                            <img src={simg} alt=""/>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-12">
                        <div className="service-grids right-col">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-facebook"></i>
                                </div>
                                <h3><Link to="/">Social Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-pay-per-click"></i>
                                </div>
                                <h3><Link to="/">Social Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-link"></i>
                                </div>
                                <h3><Link to="/">Link Building</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesSection2;