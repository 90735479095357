import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import TestimonialSlide from '../../components/Testimonial'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const TestimonialPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Testimonial'}/>
            <TestimonialSlide testclass={'testimonials-pg-section'}/>
            <Footer subclass={'site-footer-s3'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TestimonialPage;