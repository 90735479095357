import React from 'react'
import tp1 from '../../images/tips.png'
import tp2 from '../../images/check.png'

import './style.css'

const TipsTricks = (props) => {
    return(
        <section className="tips-tricks-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-md-1">
                        <div className="section-title-s2">
                            <span>Tips & Tricks</span>
                            <h2>Get Tips & Tricks About How To Grow Your Seles!</h2>
                            <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine valuealb</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-lg-6 col-md-12 col-12">
                        <div className="img-holder">
                            <img src={tp1} alt=""/>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-md-12 col-12">
                        <div className="tips-trick-grids">
                            <div className="grid">
                                <div className="icon">
                                    <img src={tp2} alt=""/>
                                </div>
                                <h4>24/7 Traffic Updates</h4>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <img src={tp2} alt=""/>
                                </div>
                                <h4>Keyword Rankings</h4>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <img src={tp2} alt=""/>
                                </div>
                                <h4>Reputation Management Briefs</h4>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TipsTricks;