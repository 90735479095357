import React from 'react'
import './style.css'
import {Link} from 'react-router-dom'
import tm1 from '../../images/team/img-1.jpg'
import tm2 from '../../images/team/img-2.jpg'
import tm3 from '../../images/team/img-3.jpg'
import tm4 from '../../images/team/img-4.jpg'

const TeamSection = (props) => {
    return(
        <section className="team-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                        <div className="section-title-s6">
                            <span>06. Team</span>
                            <h2>Meet our professional team members</h2>
                            <p> picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="team-grids">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={tm1} alt=""/>
                                </div>
                                <div className="details">
                                    <h4>Michle Jhon</h4>
                                    <p>Managing Director</p>
                                    <ul className="clearfix">
                                        <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                        <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link to="/"><i className="ti-linkedin"></i></Link></li>
                                        <li><Link to="/"><i className="ti-pinterest"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                     <img src={tm2} alt=""/>
                                </div>
                                <div className="details">
                                    <h4>Elimi Jon deo</h4>
                                    <p>Marketing Head</p>
                                    <ul className="clearfix">
                                        <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                        <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link to="/"><i className="ti-linkedin"></i></Link></li>
                                        <li><Link to="/"><i className="ti-pinterest"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={tm3} alt=""/>
                                </div>
                                <div className="details">
                                    <h4>Janck Meker</h4>
                                    <p>Managing Director</p>
                                    <ul className="clearfix">
                                        <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                        <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link to="/"><i className="ti-linkedin"></i></Link></li>
                                        <li><Link to="/"><i className="ti-pinterest"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={tm4} alt=""/>
                                </div>
                                <div className="details">
                                    <h4>Cicky Lowly</h4>
                                    <p>Hiring Manager</p>
                                    <ul className="clearfix">
                                        <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                        <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link to="/"><i className="ti-linkedin"></i></Link></li>
                                        <li><Link to="/"><i className="ti-pinterest"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamSection;