import React from 'react'
import blog1 from '../../images/blog/img-1.jpg'
import blog2 from '../../images/blog/img-2.jpg'
import blog3 from '../../images/blog/img-3.jpg'
import {Link} from 'react-router-dom'

import './style.css'

const BlogSection = (props) => {
    return(
        <section className="blog-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                        <div className="section-title-s6">
                            <span>05. Blog</span>
                            <h2>Read our latest news</h2>
                            <p> picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="blog-grids clearfix">
                            <div className="grid">
                                <div className="entry-media">
                                    <img src={blog1} alt=""/>
                                    <div className="date">Feb 01, 2020</div>
                                </div>
                                <div className="entry-details">
                                    <h3><Link to="/blogdetails">Create a unique social media marketing strategy and social </Link></h3>
                                    <Link to="/blogdetails" className="read-more">Read More</Link>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="entry-media">
                                     <img src={blog2} alt=""/>
                                    <div className="date">Feb 01, 2020</div>
                                </div>
                                <div className="entry-details">
                                    <h3><Link to="/blogdetails">Ensure that you get maximum returns on your campaign</Link></h3>
                                    <Link to="/blogdetails" className="read-more">Read More</Link>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="entry-media">
                                    <img src={blog3} alt=""/>
                                    <div className="date">Feb 01, 2020</div>
                                </div>
                                <div className="entry-details">
                                    <h3><Link to="/blogdetails">Vital role in you getting your potential business clients</Link></h3>
                                    <Link to="/blogdetails" className="read-more">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogSection;