import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import CurrentMarket from '../../components/CurrentMarket'
import PricingSection from '../../components/pricing'
import PartnerSlider from '../../components/partner'
import TestimonialSlide from '../../components/Testimonial'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const ServicePage6 =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Content Merketing'}/>
            <CurrentMarket/>
            <PricingSection/>
            <PartnerSlider/>
            <TestimonialSlide/>
            <Footer subclass={'site-footer-s3'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicePage6;