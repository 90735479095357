
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import test1 from '../../images/testimonials/01.jpg'
import test2 from '../../images/testimonials/02.jpg'
import test3 from '../../images/testimonials/03.jpg'
import test4 from '../../images/testimonials/03.jpg'

import './style.css'

class TestimonialSlide2 extends Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            margin: 10,
            loop: true,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                  }
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        return (
            <section className={`testimonials-section-s2 section-padding ${this.props.tmclass}`}>
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                            <div className="section-title-s6">
                                <span>05. Testimonials</span>
                                <h2>What Clients Say About Us!</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="testimonials-slider-s2">
                                <div className="testimonial-content-active text-center">
                                  <Slider {...settings}>
                                      <div className="grid">
                                        <div className="test-img">
                                            <img src={test1} alt=""/>
                                        </div>
                                          <p>“Dicture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered”</p>
                                          <div className="info">
                                              <h5>Michel Jhone</h5>
                                              <p>Happy client</p>
                                          </div>
                                      </div>
                                      <div className="grid">
                                          <div className="test-img">
                                              <img src={test2} alt=""/>
                                          </div>
                                          <p>“Dicture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered”</p>
                                          <div className="info">
                                              <h5>Michel Jhone</h5>
                                              <p>Happy client</p>
                                          </div>
                                      </div>
                                      <div className="grid">
                                          <div className="test-img">
                                              <img src={test3} alt=""/>
                                          </div>
                                          <p>“Dicture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered”</p>
                                          <div className="info">
                                              <h5>Michel Jhone</h5>
                                              <p>Happy client</p>
                                          </div>
                                      </div>
                                      <div className="grid">
                                          <div className="test-img">
                                              <img src={test4} alt=""/>
                                          </div>
                                          <p>“Dicture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered”</p>
                                          <div className="info">
                                              <h5>Michel Jhone</h5>
                                              <p>Happy client</p>
                                          </div>
                                      </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            );
        }
    }
    
    export default TestimonialSlide2;
          
          
          
          
