import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import simg1 from '../../images/shop/shop-single/img-1.jpg'
import simg2 from '../../images/shop/shop-single/img-2.jpg'
import simg3 from '../../images/shop/shop-single/img-3.jpg'
import simg4 from '../../images/shop/shop-single/img-4.jpg'
import simg5 from '../../images/shop/shop-single/img-5.jpg'
import simg6 from '../../images/shop/shop-single/img-6.jpg'


import tmb1 from '../../images/shop/shop-single/thumb/img-1.jpg'
import tmb2 from '../../images/shop/shop-single/thumb/img-2.jpg'
import tmb3 from '../../images/shop/shop-single/thumb/img-3.jpg'
import tmb4 from '../../images/shop/shop-single/thumb/img-4.jpg'
import tmb5 from '../../images/shop/shop-single/thumb/img-5.jpg'
import tmb6 from '../../images/shop/shop-single/thumb/img-6.jpg'

const ShopSingleImage = (props) => {
    
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
        <div className="shop-single-slider">
            <div className="slider-for">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div><img src={simg1} alt=""/></div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div><img src={simg2} alt=""/></div>
                    </TabPane>
                    <TabPane tabId="3">
                        <div><img src={simg3} alt=""/></div>
                    </TabPane>
                    <TabPane tabId="4">
                        <div><img src={simg4} alt=""/></div>
                    </TabPane>
                    <TabPane tabId="5">
                        <div><img src={simg5} alt=""/></div>
                    </TabPane>
                    <TabPane tabId="6">
                        <div><img src={simg6} alt=""/></div>
                    </TabPane>
                </TabContent>
                
            </div>
            <div className="slider-nav">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                        <div><img src={tmb1} alt=""/></div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                        <div><img src={tmb2} alt=""/></div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >
                        <div><img src={tmb3} alt=""/></div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '4' })}
                            onClick={() => { toggle('4'); }}
                        >
                        <div><img src={tmb4} alt=""/></div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '5' })}
                            onClick={() => { toggle('5'); }}
                        >
                        <div><img src={tmb5} alt=""/></div>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '6' })}
                            onClick={() => { toggle('6'); }}
                        >
                        <div><img src={tmb6} alt=""/></div>
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
        </div>

  );
}

export default ShopSingleImage;


