
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import test1 from '../../images/testimonials/group-img.png'

import './style.css'

class TestimonialSlide extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            margin: 10,
            loop: true,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                  }
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        return (
          <section className={`testimonials-section ${this.props.testclass}`}>
              <div className="container">
                  <div className="row">
                      <div className="col col-lg-6 col-md-12 col-12">
                          <img src={test1} alt=""/>
                      </div>
                      <div className="col col-lg-6 col-md-12 col-12 testimonials-area">
                          <div className="section-title-s4">
                              <span>Testimonials</span>
                              <h2>Check what’s our client say about us!</h2>
                          </div>
                            <Slider {...settings}>
                                <div className="grid">
                                    <div className="quote">
                                        <p>“Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather.”</p>
                                    </div>
                                    <div className="client-info">
                                        <h5>Michel Hone</h5>
                                        <p>Happy client, Jordan Unitad State</p>
                                    </div>
                                  </div>
                                  <div className="grid">
                                      <div className="quote">
                                          <p>“Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather.”</p>
                                      </div>
                                      <div className="client-info">
                                          <h5>Michel Hone</h5>
                                          <p>Happy client, Jordan Unitad State</p>
                                      </div>
                                  </div>
                                  <div className="grid">
                                      <div className="quote">
                                          <p>“Magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather.”</p>
                                      </div>
                                      <div className="client-info">
                                          <h5>Michel Hone</h5>
                                          <p>Happy client, Jordan Unitad State</p>
                                      </div>
                                  </div>
                              </Slider>
                        </div>
                    </div>
                </div>
            </section>
            );
        }
    }
    
    export default TestimonialSlide;
          
          
          
          
