import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import FunFact from '../../components/funfact'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const FaqPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'FAQ'}/>
            <FunFact ffclass={'faq-funfact-section-pg'}/>
            <Footer subclass={'site-footer-s3'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default FaqPage;