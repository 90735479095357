import React from 'react'
import {Link} from 'react-router-dom'


const ServicesSectionPage = (props) => {
    return(
        <section className="services-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="service-grids">
                            <div className="grid">
                                <i className="fi flaticon-rocket"></i>
                                <h3><Link to='/'>SEO Optimization</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-content"></i>
                                <h3><Link to='/'>Content Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-email"></i>
                                <h3><Link to='/'>Email Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-facebook"></i>
                                <h3><Link to='/'>Social Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-strategy"></i>
                                <h3><Link to='/'>PPC Marketing</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                            <div className="grid">
                                <i className="fi flaticon-chain"></i>
                                <h3><Link to='/'>Link Building</Link></h3>
                                <p>Magazine and housed in a gilded in frame. It showed a lady fitted wifur hat and fur who sat upright</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default ServicesSectionPage;