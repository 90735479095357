import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/pagetitle'
import ServicesSectionPage2 from '../../components/ServicesSectionPage2'
import Footer from '../../components/footerTop';
import Scrollbar from '../../components/scrollbar'


const ServicePage2 =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle pageTitle={'Service'}/>
            <ServicesSectionPage2/>
            <Footer subclass={'site-footer-s3'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicePage2;